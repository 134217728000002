import React from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import PhoneInput from "react-phone-number-input/input"
import Papa from "papaparse"
import { Typeahead } from "react-bootstrap-typeahead"

import Layout from "../../../components/layout"
import ConfianEnNosotrosart from "../../../components/confian-en-nosotros-art"

import "react-bootstrap-typeahead/css/Typeahead.css"

import bgArt from "../../../assets/old/img/cotizador/bg-art.png"
import imgArt from "../../../assets/old/img/cotizador/img-home-art.png"

export default class extends React.Component {
  state = {
    actividades: [],
  }

  componentDidMount() {
    Papa.parse("/data/art-actividades.csv", {
      download: true,
      dynamicTyping: true,
      header: true,
      complete: result => {
        this.setState({ actividades: result.data })
      },
    })
  }

  registrar(values, errorCallback) {
    let alicuotaWds = (values.actividad[0].alicuota * 0.8).toFixed(2)
    if (alicuotaWds < 0.5) alicuotaWds = 0.5
    const costoMensual = ((values.masaSalarial * alicuotaWds) / 100).toFixed(0)
    let solicitud = ""
    solicitud += `Nombre: ${values.nombre}\n`
    solicitud += `Email: ${values.email}\n`
    solicitud += `Celular: ${values.celular.replace(/^\+549?/, "")}\n`
    solicitud += `CUIT: ${values.cuit}\n`
    solicitud += `Actividad: ${values.actividad[0].id} - ${values.actividad[0].actividad}\n`
    solicitud += `Cantidad de empleados: ${values.cantidadEmpleados}\n`
    solicitud += `Masa salarial: ${values.masaSalarial}\n`
    solicitud += `Alicuota mercado: ${values.actividad[0].alicuota}\n`
    solicitud += `Alicuota WDS: ${alicuotaWds}\n`
    solicitud += `Costo mensual: ${costoMensual}\n`
    const data = {
      otro_producto_id: process.env.WOKAN_WEBPACK_OTRO_PRODUCTO_ART_ID,
      nombre: values.nombre,
      telefono: values.celular.replace(/^\+549?/, ""),
      email: values.email,
      landing: window.location.href,
      solicitud,
    }
    fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/otros/cotizacion`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_ART_SID}`,
        "Content-Type": "application/json",
      },
    })
    .then(response => response.json())
    .then(response => {
      if (!response.hasOwnProperty("token")) {
        errorCallback("Ocurrió un error, intente nuevamente más tarde por favor.")
      } else {
        values.token = response.token
        navigate('/seguros/cotizar-art/resultados', {
          state: { ...values, alicuotaWds, costoMensual }
        })
      }
    })
    .catch(err => {
      errorCallback(
        "¿Está conectado a internet? Ocurrió un error y no se puede procesar su solicitud, intente nuevamente más tarde por favor."
      )
    })
  }

  render = () => (
    <Layout menuActivo="cotizar-seguros">
      <Helmet>
        <title>Seguros de ART</title>
        <meta name="description" content="Cotizar seguro de ART." />
      </Helmet>

      <section className="bg-w pt-3 pb-5 bghome-art" style={{ backgroundImage: `url(${bgArt})` }}>
        <div className="container text-center pt-2 pb-5 mb-5">
          <div className="row pb-1 mb-3">
            <div className="col-md-12 mb-30">
              <h1 className="display-4 text-uppercase">
                <span className="text-primary">¡</span>Cotiza seguro de ART online
                <span className="text-primary">!</span>{" "}
                <span role="img" aria-label="Herramienta">
                  🛠️
                </span>
              </h1>
              <h2 className="h4 block-title text-center mt-1 text-color-444">
                Mantené tu seguridad ahorrando hasta un 20%{" "}
                <span role="img" aria-label="Corneta">
                  📢
                </span>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="container pb-3 cotizador-gral-art">
        <div className="bg-white4 box-shadow-home px-3 px-sm-5 pt-2 pb-2 text-center text-sm-left">
          <Formik
            initialValues={{
              cuit: "",
              actividad: "",
              cantidadEmpleados: "",
              masaSalarial: "",
              nombre: "",
              celular: "",
              email: "",
            }}
            validationSchema={() =>
              Yup.object().shape({
                cuit: Yup.string()
                  .trim()
                  .matches(/^\d{10,11}$/, "Ingrese CUIT completo sin guiones")
                  .required("Requerido"),
                actividad: Yup.string().required("Requerido"),
                cantidadEmpleados: Yup.number().required("Requerido"),
                masaSalarial: Yup.number().required("Requerido"),
                nombre: Yup.string()
                  .trim()
                  .required("Requerido"),
                celular: Yup.string()
                  .trim()
                  .matches(/^\+549?\d{10}$/, "Ingrese código de área y número")
                  .required("Requerido"),
                email: Yup.string()
                  .email("Inválido")
                  .required("Requerido"),
              })
            }
            onSubmit={(values, { setSubmitting }) => {
              if (window.gtag) {
                window.gtag("event", "art_cotizar")
              }
              this.registrar(values, error => {
                alert(error)
                setSubmitting(false)
              })
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form className="pt-2">

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="text-primary">Actividad</label>
                      <Field name="actividad">
                        {({ field, form, meta }) => (
                          <Typeahead
                            options={this.state.actividades}
                            labelKey={option => `${option.actividad}`}
                            name={field.name}
                            id={field.name}
                            value={field.value}
                            onChange={val => {
                              setFieldValue(field.name, val)
                              if (window.gtag) {
                                window.gtag("event", "art_actividad")
                              }
                            }}
                            onBlur={e => handleBlur(e)}
                            inputProps={{
                              name: field.name,
                              id: field.id,
                            }}
                            className={`${errors.cuit && touched.cuit && "is-invalid"}`}
                            isInvalid={errors.actividad && touched.actividad}
                            placeholder="Ingrese parte del nombre de la actividad para buscar"
                            paginationText="Mostrar más..."
                            emptyLabel="No se encontraron actividades"
                            minLength={3}
                            maxResults={15}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="actividad"
                        component="div"
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="text-primary">CUIT Empresa</label>
                      <Field
                        name="cuit"
                        className={`form-control ${errors.cuit && touched.cuit && " is-invalid"}`}
                        type="number"
                        placeholder="Ej: 30684124184 (sin guiones)"
                        onBlur={async e => {
                          handleBlur(e)
                          if (window.gtag) {
                            window.gtag("event", "art_cuit")
                          }
                        }}
                      />
                      <ErrorMessage name="cuit" component="div" className="invalid-feedback" />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6">
                    <div className="form-group">
                      <label className="text-primary">Cantidad Empleados</label>
                      <Field
                        name="cantidadEmpleados"
                        className={`form-control ${errors.cantidadEmpleados &&
                          touched.cantidadEmpleados &&
                          " is-invalid"}`}
                        type="number"
                        placeholder="Ej: 2"
                        onBlur={async e => {
                          handleBlur(e)
                          if (window.gtag) {
                            window.gtag("event", "art_cantidad_empleados")
                          }
                        }}
                      />
                      <ErrorMessage
                        name="cantidadEmpleados"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6">
                    <div className="form-group">
                      <label className="text-primary">Masa Salarial ($)</label>
                      <Field
                        name="masaSalarial"
                        className={`form-control ${errors.masaSalarial &&
                          touched.masaSalarial &&
                          " is-invalid"}`}
                        type="number"
                        placeholder="Ej: 40000"
                        onBlur={async e => {
                          handleBlur(e)
                          if (window.gtag) {
                            window.gtag("event", "art_masa_salarial")
                          }
                        }}
                      />
                      <ErrorMessage
                        name="masaSalarial"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-sm-6">
                    <div className="form-group">
                      <label className="text-primary">Nombre y apellido</label>
                      <Field
                        name="nombre"
                        className={`form-control ${errors.nombre &&
                          touched.nombre &&
                          " is-invalid"}`}
                        type="text"
                        placeholder="Ej: Francisco Blanco"
                        onBlur={async e => {
                          handleBlur(e)
                          if (window.gtag) {
                            window.gtag("event", "art_nombre")
                          }
                        }}
                      />
                      <ErrorMessage name="nombre" component="div" className="invalid-feedback" />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6">
                    <div className="form-group">
                      <label className="text-primary">Celular</label>
                      <Field name="celular">
                        {({ field, form, meta }) => (
                          <PhoneInput
                            name={field.name}
                            value={field.value}
                            onChange={(val) => { setFieldValue(field.name, val) }}
                            onBlur={async e => {
                              handleBlur(e)
                              if (window.gtag) {
                                window.gtag("event", "art_celular")
                              }
                            }}
                            country="AR"
                            placeholder="Ej: 11 7777-8888"
                            className={`form-control ${errors.celular &&
                              touched.celular &&
                              " is-invalid"}`}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="celular" component="div" className="invalid-feedback" />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6">
                    <div className="form-group">
                      <label className="text-primary">Email</label>
                      <Field
                        name="email"
                        className={`form-control ${errors.email && touched.email && " is-invalid"}`}
                        type="email"
                        placeholder="Ej: fblanco@empresa.com"
                        onBlur={async e => {
                          handleBlur(e)
                          if (window.gtag) {
                            window.gtag("event", "art_email")
                          }
                        }}
                      />
                      <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary btn-block mt-4"
                      >
                        {isSubmitting ? "Un momento por favor..." : "¡Ver mi cotización!"}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>

      {/* Animacion */}
      <section className="bg-footer-art">
        <div>
          <img className="img-portada-home-art d-block" src={imgArt} alt="Trabajando Seguro" />
        </div>
      </section>

      {/* Aseguradoras */}
      <ConfianEnNosotrosart />

    </Layout>
  )
}
