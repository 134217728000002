import React from "react"
import { Link } from "gatsby"
import icoAsociart from "../assets/old/img/logos-art/asociart.png"
import icoBerkleyart from "../assets/old/img/logos-art/berkley.png"
import icoExpertaart from "../assets/old/img/logos-art/experta.png"
import icoFederacion from "../assets/old/img/logos-art/federacion.png"
import icoGalenoart from "../assets/old/img/logos-art/galeno.png"
import icoOmint from "../assets/old/img/logos-art/omint.png"
import icoPrevencion from "../assets/old/img/logos-art/prevencion.png"
import icoProvinciaart from "../assets/old/img/logos-art/provincia.png"
import icoSmg from "../assets/old/img/logos-art/smg.png"

export default () => (
  <>
    <section className="container" data-offset-top="110" id="aseguradoras">
      <div className="container pt-1 mt-2 pb-5">
        <h2 className="h3 block-title text-center pt-5 mt-2">
          Confían en nosotros ART
        </h2>
        <div className="row pt-4">
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros">
              <img
                className="d-block mx-auto"
                src={icoAsociart}
                style={{ padding: "30px 0" }}
                alt="Asociart"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros">
              <img
                className="d-block mx-auto"
                src={icoBerkleyart}
                style={{ padding: "30px 0" }}
                alt="Berkley ART"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros">
              <img
                className="d-block mx-auto"
                src={icoExpertaart}
                style={{ padding: "30px 0" }}
                alt="Experta ART"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros">
              <img
                className="d-block mx-auto"
                src={icoFederacion}
                style={{ padding: "30px 0" }}
                alt="Federaciòn ART"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros">
              <img
                className="d-block mx-auto"
                src={icoGalenoart}
                style={{ padding: "30px 0" }}
                alt="Galeno ART"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros">
              <img
                className="d-block mx-auto"
                src={icoOmint}
                style={{ padding: "30px 0" }}
                alt="Omint"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros">
              <img
                className="d-block mx-auto"
                src={icoPrevencion}
                style={{ padding: "30px 0" }}
                alt="Prevenciòn"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros">
              <img
                className="d-block mx-auto"
                src={icoProvinciaart}
                style={{ padding: "30px 0" }}
                alt="Provincia ART"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros">
              <img
                className="d-block mx-auto"
                src={icoSmg}
                style={{ padding: "30px 0" }}
                alt="SMG"
              />
            </Link>
          </div>


        </div>
      </div>
    </section>
  </>
)
